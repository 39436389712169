<template>
  <div>
    <div class="back-btn">
      <div @click="toBack">
        <img style="width: 15px" src="../../assets/images/goback.png" alt="" />
        <span style="margin-right: 15px">正在业务测评</span>
      </div>
    </div>
    <div class="exam" v-loading="loading">
      <p class="title">
        业务测评时间剩余：<span class="span1">{{ branch + ":" + second }}</span
        ><span class="span2">分钟</span>
      </p>
      <div class="span-btn">
        <div class="left">
          <span>单选题（{{ isAnswer.length }}/{{ questionList.length }}）</span>
          <span @click="dialogVisible = true"
            ><img src="../../assets/images/learn4.png" />查看全部题目</span
          >
          <span @click="restart"
            ><img src="../../assets/images/learn5.png" />全部重做</span
          >
        </div>
        <el-button class="_ImgBtn" @click="submit"
          ><img src="../../assets/images/learn6.png" />交卷</el-button
        >
      </div>
      <p class="text">开始答题<span>（每题仅有一个答案，共100分）</span></p>
      <div class="questions" :style="{ height: elementHeight + 'px' }">
        <div v-for="(item, index) in questionList" :key="index">
          <p>{{ index + 1 }}、{{ item.question }}</p>
          <div>
            <el-radio
              v-model="item.answer"
              label="是"
              @change="clickAnswer(item)"
              >是</el-radio
            >
            <el-radio
              v-model="item.answer"
              label="否"
              @change="clickAnswer(item)"
              >否</el-radio
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="查看全部问题"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog">
        <span
          v-for="(item, index) in questionList"
          :key="index"
          :class="{ item_active: item.answer != '' }"
          @click="clickItem(index)"
          >{{ index + 1 }}</span
        >
      </div>
    </el-dialog>
    <el-dialog
      title="查看全部问题"
      :visible.sync="dialogVisible2"
      width="30%"
      :before-close="handleClose2"
    >
      <span>{{ itemIndex + 1 }}、{{ dataItem.question }}</span>
      <div style="padding-top: 10px">
        <el-radio
          v-model="dataItem.answer"
          label="是"
          @change="clickAnswer(dataItem)"
          >是</el-radio
        >
        <el-radio
          v-model="dataItem.answer"
          label="否"
          @change="clickAnswer(dataItem)"
          >否</el-radio
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod, baseURL } from "../../api/http";
export default {
  data() {
    return {
      radio: "",
      dialogVisible: false,
      branch: "60",
      second: "00",
      time: null,
      questionList: [],
      dataItem: {},
      compName: "",
      deptName: "",
      record: 0,
      companyId: 0,
      deptId: 0,
      postId: 0,
      beforeUnloadTime: 0,
      elementHeight: 0,
      itemIndex: 0,
      isAnswer: [],
      dialogVisible2: false,
      loading:false,
    };
  },
  mounted() {
    this.getElementHeight();
    const user = this.$store.state.userInfo;
    this.companyId = user.upCompanyId;
    this.deptId = user.upDeptmentId;
    this.postId = user.post;
    this.getExamId(user.id);
    // 切换标签时
    document.addEventListener("visibilitychange", this.handleVisibitState);
    // 刷新或关闭标签时
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", this.setData);
  },
  methods: {
    toBack(){
      this.$confirm('是否退出业务测评?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.go(-1);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 80 + 150); //70是div底部距离底部的高度
      });
    },
    handleVisibitState() {
      if (document.visibilityState == "hidden") {
        clearInterval(this.time);
      } else if (document.visibilityState == "visible") {
        this.timeFlow();
      }
    },
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime();
    },
    setData() {
      // 刷新比关闭时间短
      clearInterval(this.time);
      var timeDifference = new Date().getTime() - this.beforeUnloadTime; //获取时间差
      if (timeDifference <= 5) {
        this.automaticSubmit(); //关闭
      } else {
        this.setTime(); //刷新
      }
    },
    automaticSubmit() {
      fetch(
        baseURL[process.env.NODE_ENV + ""] +
          "/api/ExamPlan/CompleteExam?ExamPlanId=" +
          this.questionList[0].examPlanId,
        {
          method: "PUT",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
          },
          keepalive: true,
        }
      );
    },
    setTime() {
      var time = this.branch + ":" + this.second;
      fetch(
        baseURL[process.env.NODE_ENV + ""] +
          "/api/ExamPlan/UpdateTime?ExamPlanId=" +
          this.questionList[0].examPlanId +
          "&ExamTime=" +
          time,
        {
          method: "PUT",
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
          },
          keepalive: true,
        }
      ).catch((e) => {
        console.log(e);
      });
    },
    // 倒计时显示
    timeFlow() {
      this.time = setInterval(() => {
        if (this.branch == "00" && this.second == "00") {
          clearInterval(this.time);
          return;
        }
        if (this.second == "00") {
          this.second = "60";
          this.branch--;
        }
        if (this.branch == "9") {
          this.branch = "09";
        }
        this.second--;
        if (this.second < 10) {
          this.second = "0" + this.second;
        }
      }, 1000);
    },
    // 获取题目
    async getCompyId() {
      await get("/api/Company/" + this.companyId).then((resp) => {
        if (resp.data != null && resp.data.length != 0)
          this.compName = resp.data.companyName;
      });
      await get("/api/Department/GetById?Id=" + this.deptId).then((resp) => {
        if (resp.data != null && resp.data.length != 0)
          this.deptName = resp.data.deptmentName;
      });
      this.getQuestionList();
    },
    getQuestionList() {
      var user = this.$store.state.userInfo;
      var data = {
        id: 0,
        compId: this.companyId,
        compName: this.compName,
        deptId: this.deptId,
        deptName: this.deptName,
        examUserId: user.id,
        examUserName: user.userName,
        examScore: 0,
        status: 0,
        examTime: "60:00",
        remark: "",
        saveTime: new Date().toISOString(),
        saveUserId: user.saveUserId,
        saveUserNo: user.userNo,
        saveUserName: user.userName,
      };
      //新增业务测评记录
      var url = "/api/ExamPlan";
      if (this.postId) {
        url = url + "?postId=" + this.postId;
      }
      post(url, data).then((resp) => {
        if (resp.code == 200) {
          //  查询业务测评记录id
          this.getExamId(user.id);
        } else {
          if (resp.message == "业务测评题库为空！") {
            this.$confirm("业务测评题库为空，去导题")
              .then(() => {
                this.$router.push("./exam");
              })
              .catch(() => {
                this.$router.push("./exam");
              });
          }
        }
      });
    },
    getExamId(userId) {
      get("/api/ExamPlan/GetByUserId?UserId=" + userId).then((resp) => {
        if (resp.code == 200) {
          // 获取题目前还要判断是否存在未完成的业务测评
          var list = resp.data.filter((i) => {
            return i.status != 2;
          });
          if (list.length == 0) {
            this.getCompyId();
          } else {
            if (list[0].examTime != "") {
              this.branch = list[0].examTime.split(":")[0] + "";
              this.second = list[0].examTime.split(":")[1] + "";
            }
            this.companyId = list[0].compId;
            this.deptId = list[0].deptId;
            this.postId = list[0].postId;
            this.record = list[0].id;
            //获取题目
            get(
              "/api/ExamPlanList/GetByExamPlanId?ExamPlanId=" + list[0].id
            ).then((resp) => {
              if (resp.code == 200) {
                this.questionList = resp.data;
                this.timeFlow();
                this.handleAnswer();
              }
            });
          }
        } else {
          this.$message.error(resp.message);
        }
      });
    },
    // 答题、交卷
    clickAnswer(e) {
      if (this.branch == "00" && this.second == "00") {
        this.$message({
          type: "warning",
          message: "您已无作答时间，请交卷",
        });
        return;
      }
      put("/api/ExamPlanList", e)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error(resp.message);
          } else {
            this.handleAnswer();
          }
        })
        .catch(() => {
          this.$message.error("系统异常，请稍后再试");
        });
    },
    handleAnswer() {
      this.isAnswer = this.questionList.filter((item) => {
        return item.answer != "";
      });
    },
    submit() {
      put(
        "/api/ExamPlan/CompleteExam?ExamPlanId=" +
          this.questionList[0].examPlanId
      ).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功",
          });
          var done = [];
          var noDone = [];
          var error = [];
          this.questionList.map((i, index) => {
            if (i.answer == i.quesAnswer && i.answer) {
              done.push(index + 1);
            }
            if (i.answer == "") {
              noDone.push(index + 1);
            }
            if (i.answer != i.quesAnswer && i.answer) {
              error.push({
                index: index + 1,
                id: i.id,
              });
            }
          });
          var baseUrl =
            "./user-score?id=" +
            this.record +
            "&done=" +
            JSON.stringify(done) +
            "&noDone=" +
            JSON.stringify(noDone) +
            "&error=" +
            JSON.stringify(error);
          if (this.$route.query.index == 1) {
            var url =
              baseUrl +
              "&companyId=" +
              this.companyId +
              "&deptId=" +
              this.deptId;
            if (this.postId) {
              url = url + "&postId=" + this.postId;
            }
            this.$router.push(url);
          } else {
            this.$router.push(baseUrl);
          }
        }
      });
    },
    handleUpTime() {
      var time = this.branch + ":" + this.second;
      put(
        "/api/ExamPlan/UpdateTime?ExamPlanId=" +
          this.questionList[0].examPlanId +
          "&ExamTime=" +
          time
      );
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose2() {
      this.dialogVisible2 = false;
    },
    clickItem(i) {
      this.itemIndex = i;
      this.dataItem = this.questionList[i];
      console.log(this.questionList[i]);
      this.dialogVisible2 = true;
    },
    restart() {
      this.$confirm("是否清空已答问题全部重新开始？")
        .then(() => {
          this.loading = true
          deleteMethod("/api/ExamPlan?Id=" + this.questionList[0].examPlanId)
            .then((resp) => {
              if (resp.code == 200) {
                this.branch = "60";
                this.second = "00";
                clearInterval(this.time);
                this.getQuestionList();
                this.loading = false;
              } else {
                this.$message.error(resp.message);
                this.loading = false;
              }
            })
            .catch(() => {
              this.$message.error("失败");
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.time);
    document.removeEventListener("visibilitychange", this.handleVisibitState);
    window.removeEventListener("beforeunload", this.setTime);
    if (this.questionList.length == 0) return;
    this.handleUpTime();
  },
};
</script>

<style lang="less" scoped>
.back-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 20px;
  background-image: url("../../assets/images/top.png");
  background-size: 100% 100%;
}
.exam {
  position: absolute;
  width: 80%;
  background-color: white;
  height: 80vh;
  top: 10vh;
  right: 20vh;
  .title {
    font-size: 17px;
    padding: 10px;
    color: rgb(136, 136, 136);
    border-bottom: 1px solid rgb(229, 229, 229);
    .span1 {
      color: red;
    }
    .span2 {
      color: black;
    }
  }
  .span-btn {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgb(229, 229, 229);
    padding-bottom: 10px;
    .left {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        background-color: #f0f0f0;
        margin-right: 10px;
        font-size: 12px;
        height: 25px;
        padding: 0 5px;
      }
    }
    ._ImgBtn {
      background-color: #4985ff !important;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white !important;
      margin-right: 10px;
    }
  }
  .text {
    padding-top: 10px;
    padding-left: 10px;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    span {
      color: rgb(136, 136, 136);
    }
  }
  .questions {
    padding-left: 20px;
    margin-top: 15px;
    overflow: scroll;
    p {
      padding-bottom: 5px;
    }
    > div {
      margin-bottom: 20px;
    }
  }
}
.dialog {
  display: flex;
  flex-wrap: wrap;
  span {
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 50px;
    margin-right: 10px;
  }
}
.item_active {
  color: white !important;
  background-color: #5a8bff !important;
  border: 1px solid #858585 !important;
}
</style>